@import "animate.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

html, body {
  @apply h-full w-full;
}

#root {
  @apply h-full w-full;
}
