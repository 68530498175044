.markdownContainer {
  * {
    text-wrap: unset;
    word-break: break-word;
    @apply m-0;
  }

  li {
    list-style: decimal;
  }

  img {
    max-width: 100%;
    cursor: zoom-in;
  }

  code {
    @apply p-0;
    font-size: 12px;
  }

  table, th, td {
    border: 1px solid;
    border-collapse: collapse;
    font-size: 13px;
  }

  th, td {
    @apply px-2 py-0.5;
  }

  th {
    @apply text-center;
  }

  a {
    @apply text-primary;
  }
}
